body{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;
    background-color: $color-theme;
}

.style-2{
    font-family: 'lato';
}

.h1, .h2, .h3, .h4{
    font-weight: bold;
}

.h1{
    font-size: 3.6rem;
}

.h2{
    font-size: 2.4rem;
}

.h3{
    font-size: 1.6rem;
}

.h4{
    font-size: 1.2rem;
}

.body{
    &-1{
        font-size: 1.6rem;
    }
    &-2{
        font-size: 1.4rem;
    }
    &-3{
        font-size: 1.2rem;
    }
}