.title{
    &__section{
        &--title{
            padding-left: 2rem;
            border-left: solid $color-theme 6px;

            &-box{
                padding-top: 4rem;
                margin-left: 4rem;
            }
        }
    }
}