//link-reset
@mixin link-reset{
    &,
    &:link,
    &:visited{
        text-decoration: none;
        position: relative;
    }
}

//layout
@mixin center-horizontal-vertical{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin flex-center{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    align-self: center;
}
@mixin border-top-radius-default{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

@mixin border-bottom-radius-default{
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

@mixin border-bottom-divide{
    border-bottom: rgba($color-primary, .9) solid 3px;  
}

@mixin border-bottom-divide-padding{
    @include border-bottom-divide;
    padding-bottom: 3.7rem;
}


//MEDIA QUERIES

@mixin respond($breakpoint){
    @if $breakpoint == xs{
        @media only screen and (max-width: 31.25em) {@content}; //500px
    }
    @if $breakpoint == sm {
        @media only screen and (max-width: 37.5em) {@content};  //600px    
    }
    @if $breakpoint == md {
        @media only screen and (max-width: 56.25em) {@content};   //1000px
    }
    @if $breakpoint == lg {
        @media only screen and (max-width: 75em) {@content};  //1200px  
    }
    @if $breakpoint == xl {
        @media only screen and (min-width: 112.5em) {@content};  //1800px
    } 
}


//not hovers

@mixin not-hover--grayscale{
    &:hover > *:not(:hover){
        filter: grayscale(100%);
        transform: scale(.95)
    }
}
@mixin not-hover--opacity{
    &:hover > *:not(:hover){
        opacity: .8;
        transform: scale(.95)
    }
}



//Gradients

@mixin color-gradient-primary-1{ background: linear-gradient(139.74deg, $color-primary-1 0%, rgba($color-primary-4, 0) 103.2%); }
@mixin color-gradient-primary-1--reverse{ background: linear-gradient(139.74deg, rgba($color-primary-4, 0) 0%, #5B83AA 103.2%); }
@mixin color-gradient-primary-2{ background: linear-gradient(139.74deg, $color-primary-1 0%, $color-primary-4 103.2%); }

@mixin color-gradient-semantic-1{ background: linear-gradient(135.15deg, $color-accent-1 3.75%, rgba($color-accent-4, 0) 96.5%); }
@mixin color-gradient-semantic-1--reverse{ background: linear-gradient(135.15deg, $color-accent-1 3.75%, rgba($color-accent-4, 0) 96.5%); }
@mixin color-gradient-semantic-2{ background: linear-gradient(139.74deg, $color-accent-1 0%, $color-accent-4 103.2%); }



//Button reset
@mixin button-reset{
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    line-height: auto;
    font-family: inherit;
    color: inherit;
    outline: none;
    text-decoration: none;
    background: none;
}
@mixin default-btn{
    background-color: $color-semantic-b-2;
    transition: all .2s;
    &:hover{
        background-color: $color-semantic-b-1;
        transform: translateY(-2px);
        box-shadow: $shadows-2;
    }
    &:focus{
        background-color: $color-semantic-b-1;
        transform: translateY(1px);
    }
    &:active{
        transform: translateY(1px);
    }
}

@mixin borders{
    border-right: solid 5rem $color-primary-1;
    border-left: solid 5rem $color-primary-1;
    @include respond(xs){
        border-right: solid 3rem $color-primary-1;
        border-left: solid 3rem $color-primary-1;
      }
}

@mixin padding{
    padding: 5rem;
    @include respond(md){
        padding: 3rem;
    }
    @include respond(xs){
        padding: 2.5rem 2rem;
    }
}

@mixin default-margin{
    margin-bottom: 5rem;
    @include respond(md){
        margin-bottom: 4rem;
    }
    @include respond(xs){
        margin-bottom: 3rem;
    }
}

