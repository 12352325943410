.media-card{
    &-box{
        display: flex;
        justify-content: center;
        background-color: $color-theme;
        box-shadow: $shadows-1;
        padding: 2.5rem 5rem;
    }
    &__item{
        color: $color-semantic-b-3;
        font-size: 5rem;
        transition: all .2s;
        &:not(:last-child){
            margin-right: 5rem;
        }
        &:hover{
            color: $color-semantic-b-1;
            transform: translateY(-2px);
        }
    }
    &__footer{
        &-box{
            @include respond(md){
                width: 100%;
            }
            
        }
    }
}