.tab-image{
    &-box{
        @include borders;
        @include padding;
        @include default-margin;
        box-shadow: $shadows-1;
    }
    &__descriptor{
        &-box{
            display: flex;
            flex-direction: column;
            margin-bottom: 2.5rem;
        }
        &--title{
            font-size: 3.6rem;
            font-weight: bold;
            color: $color-accent-1;
            padding-bottom: 1.4rem;
            
            border-bottom: solid .6rem $color-primary-2;
            &-box{
                display: flex;
                margin-bottom: 2rem;
            }
        }
        &--content{
            font-family: lato;
            color: $color-grey-a-1;
            font-size: 1.8rem;
            box-shadow: $shadows-1;
            padding: 2rem 1.5rem;
            @include respond(xs){
                font-size: 2.2rem;
            }
        }
    }
    &__selector{
        color: $color-primary-5;
        &-box{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
            grid-gap: 2.5rem;
            margin-bottom: 2.5rem;
        }
        &-tab{
            padding: 1.8rem 4.2rem;
            @include respond(md){
                padding: 1.6rem 1.2rem;
            }
            @include respond(xs){
                padding: 1.4rem 1.2rem;
            }
        }
    }
    &__content{
       
        &-box{
            &--40{
                height: 40rem; 
            }
            &--60{
                height: 60rem; 
            }
        }
        &--image{
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: contain;
            &--cover{
                object-fit: cover;
            }
            &-box{
                position: relative;
                height: 30rem;
                @include respond(md){
                    height: 21rem;
                }
                @include respond(xs){
                    height: 11rem;
                }
                &--40{
                    height: 40rem; 
                }
                &--60{
                    height: 60rem; 
                }
            }
        }
    }

    &__caption{
        margin-top: 2rem;
        padding: 2rem 1.5rem;
        box-shadow: $shadows-1;
        &--content{
            font-family: lato;
            font-size: 1.8rem;
            
            &:not(:last-child){
                margin-bottom: 1rem;
            }
            @include respond(xs){
                font-size: 2.2rem;
            }
        }
        
        &-box{
            margin-bottom: 2.5rem;
            margin-top: 1.5rem;
            border-top: solid 0.6rem $color-primary-4;
        }
    }
}