.profile-comment{
    &--title{
        background-color: $color-primary-1;
        color: $color-primary-5;
        padding: 1.1rem 3.5rem;
        @include respond(xs){
            padding: 1rem;
        }
    }
    &__comment{
        font-size: 1.6rem;
        color: $color-grey-a-2
    }
    &-box{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5rem;
        padding: 5rem;
        @include respond(md){
            grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
        }
        @include respond(xs){
            padding: 3rem 1rem;
        }
    }
    &__item{
        & > * {
            margin-bottom: 2rem;
        }
        &--profile{
            display: flex;
            &-img{
                border-radius: 200rem;
                border: solid $color-primary-1 3px;
                height: 7rem;
                width: 7rem;
            }
        }
        &--title{
            color: $color-grey-a-1;
            &-box{
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 2rem;
            }
        }
        &--subtitle{
            color: $color-grey-a-2;
        }
    }
}