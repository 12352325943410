//COLOR SCHEME

//PRIMARY
$color-primary-1: #5b83aa;
$color-primary-2: #84A2BF;
$color-primary-3: #ADC1D4;
$color-primary-4: #D6E0EA;
$color-primary-5: #EFF3F7;

//ACCENT
$color-accent-1: #AA5B83;
$color-accent-2: #BF84A2;
$color-accent-3: #D4ADC1;
$color-accent-4: #EAD6E0;

//SEMANTIC
$color-semantic-a-1: #AA4E5B;
$color-semantic-a-2: #BF7A84;
$color-semantic-a-3: #D4A6AD;

$color-semantic-b-1: #7A49A5;
$color-semantic-b-2: #A584C2;
$color-semantic-b-3: #BCA4D2;

//GREY
$color-grey-a-1: #373F51;
$color-grey-a-2: #696F7C;
$color-grey-a-3: #9B9FA8;
$color-grey-a-4: #CDCFD3;

$color-grey-b-1: #343434;
$color-grey-b-2: #676767;
$color-grey-b-3: #9A9999;
$color-grey-b-4:#CCCCCC;

$color-theme: #fff;

// gap stuff
$grid-gap--sm: 2rem;
$grid-gap--md: 3rem;
$grid-gap--lg: 4rem;

//margin-bottom
$margin-bottom--sm: 1rem;
$margin-bottom--md: 2rem;
$margin-bottom--lg: 3rem;
$margin-bottom--xl: 4rem;

//border-radius
$border-radius--default: 3px;

//Shadows

$shadows-1: 1px 5px 10px rgba(0, 0, 0, 0.2);
$shadows-2: 0px 4px 12px rgba($color-accent-1, 0.1);
$shadows-4: 1px 5px 14px rgba(0, 0, 0, 0.1);


