.pages-general{
    margin: 7rem 4rem 4rem;
    @include respond(md){
        margin: 5.5rem 4rem 4rem;
    }
    @include respond(sm){
        margin: 2rem;
    }
    @include respond(xs){
        margin: 4rem 1rem 2rem;
    }
}