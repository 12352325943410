.u-center-text{text-align: center !important;}

.u-margin-bottom-sm{margin-bottom: 1.5rem !important;}
.u-margin-bottom-md{ margin-bottom: 4rem !important; }
.u-margin-bottom-lg{ margin-bottom: 8rem !important; }

.u-margin-top-sm{ margin-top: 1.5rem !important; }
.u-margin-top-sm-pl{ margin-top: 2rem !important; }
.u-margin-top-md{ margin-top: 4rem !important; }
.u-margin-top-lg{margin-top: 8rem !important;}
.u-margin-top-xl{margin-top: 10rem !important;}

.u-flex-center{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.u-inline-block{
    display: inline-block !important;
}
.u-inline{
    display: inline;
}

.u-img-100{
    width: 100%;
    height: 100%;
}

.u-title-2{
    font-size: 2.6rem;
}
.u-title-3{
    font-size-adjust: 2.2rem;
}


.u-grid-box-col-2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    @include respond(sm){
        grid-template-columns: 1fr;
    }
    &-lg{
        @include respond(lg){
            grid-template-columns: 1fr;
        }
    }
}

.u-grid-box-col-3{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;

        @include respond(md){
            grid-template-columns: 1fr 1fr;
            row-gap: 1.4rem;
        }
        @include respond(sm){
            grid-template-columns: 1fr;
            row-gap: 1.4rem;
        }
}

.u-display-none{
    display: none !important;
}
