.nav{
    display: flex;
    flex-direction: column;
    background-color: $color-primary-1;
    position: sticky;
    z-index: 20000;
    box-shadow: $shadows-1;
    top: 0;
    &__list{
        display: flex;
        justify-content: flex-end;
        &:last-child{
            margin-right: 4rem;
            @include respond(md){
                margin-right: 2rem;
            }
        }
    }
    &__item{
        display: flex;
        justify-content: center;
        position: relative;
        
    }
    &--active{
        background-color: $color-accent-1;
        &:focus{
            background-color: $color-accent-1 !important;
            color: #fff !important;
        }
        &:hover{
            background-color: $color-accent-1 !important;
            color: #fff !important;
        }
    }
    &--animation{
        animation: shake .4s;
    }

    &__link{
        display: inline-block;
        color: #fff;
        text-decoration: none;
        padding: 1.6rem 3rem;
        transition: all .2s;
        @include respond(md){
            padding: 1.6rem 2rem;
            font-size: 2.2rem;
        }
        @include respond(xs){
            padding: 1.6rem 2rem;
        }
        &:hover{
            background-color: $color-primary-3;
            color: $color-grey-a-1;
        }


        &-dropdown{
            cursor: pointer;
            text-decoration: none;
            background-color: inherit;
            letter-spacing: inherit;
            border: none;
            line-height: inherit;
            &:focus{
                background-color: $color-primary-2;
            }
            
        }
        &:hover{
            transform: translateY(0);
            color: $color-primary-1;
        }
        &:focus,
        &:active{
            outline: none;
            transform: translateY(0);
            color: $color-primary-1;
            background-color: $color-primary-3;
        }
        
    }

    &__copyright{
        text-decoration: none;
        color: $color-theme;
        position: absolute;
        left: 4rem;
        top: 0;
        height: 100%;

        @include respond(xs){
            left: 2rem;
            
        }
        &-box{
            display: flex;
            align-items: flex-end;
            line-height: .6;
        }
        &-special{
            color: $color-accent-4;
            min-width: max-content;
        }
    }
}

.dropdown{
    &__content{
        display: none;
        position: absolute;
        list-style-type: none;
        top: 100%;
        width: 100%;
        box-shadow: $shadows-1;

    }
    &__link{
        display: block;
        transition: all .4s;
        border-radius: 0;
        color: #fff;
        text-decoration: none;
        font-size: 2.4rem;
        padding: 1.6rem 0;
        @include respond(md){
            font-size: 1.7rem;
        }
        &:hover{
            box-shadow: none;
            transform: translateY(0);

        }
        &-icon{
            margin-left: 1rem;
        }
    }
    &__item{
        border-top: 1px $color-primary-5 solid;
    }
}

.show{
    display:block;
}