.video{
    object-fit: contain;
    width: inherit;
    height: 70rem;
    @include respond(md){
        height: 40rem;
    }
    @include respond(xs){
        height: 30rem;
    }
    &-box{
        background-color: $color-theme;
        box-shadow: $shadows-1;
        margin-bottom: 5rem;
        width: 100%;
        @include padding;
        @include borders;
        @include default-margin;
    }
    
}