.add-card{
    background-color: $color-primary-4;
    padding: 2.4rem;
    border: solid 1.2rem $color-primary-1;
    color: $color-grey-a-1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--gradient{
        border: 10px solid;
        border-image-source: linear-gradient(139.74deg, $color-primary-1 0%, rgba($color-primary-4, 0) 103.2%);
        border-image-slice: 1;
    }

    &__box{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
        grid-gap: 1.5rem;
        padding: 4rem;
    }
    &__divider{
        border: 1px solid $color-grey-a-1;
        margin: 1rem 0;
        &--budgety{
            margin: 2rem 0;
        }
        
    }
    &__content{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        & > *{
            margin-bottom: 1.2rem;
        }
    }

    &__btn{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        &--mobile{
            @include respond(md){
                display: none;
            }
        }
    }
}