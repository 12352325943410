.main-title{
    margin-left: 12rem;
    position: relative;
    @include respond(md){
        margin-left: 0;
    }

    &-box{
        display: flex;
        margin-bottom: 5rem;
        @include respond(md){
            display: grid;
            grid-gap: 3rem;
        }
        @include respond(xs){
            margin-top: 3rem;
            grid-gap: 0;
        }
    }
    
    &__title{
        & > * {
            font-weight: 500;
        }
        &--name{
            font-size: 5.8rem;

            @include respond(md){
                font-size: 4.8rem;
            }
            @include respond(xs){
                font-size: 3.2rem;
                display: inline-block;
            }
        }
        &--slash{
            width: .5rem;
            height: 8rem;
            background-color: $color-primary-1;
            transform: rotate(45deg);
            margin: 0 3rem 0 4rem;
            @include respond(md){
                display: none;
            }
        }
        &--job{
            font-size: 4.2rem;
            color: $color-accent-1;
            @include respond(md){
                font-size: 3.6rem;
            }
            @include respond(xs){
                font-size: 2.4rem;
            }
        }

        &-box{
            display: flex;
            align-items: flex-end;
            color: $color-grey-a-1;
            margin-bottom: 6rem;
            animation: moveInTop 1.2s cubic-bezier(0.33, 1, 0.68, 1);
            @include respond(md){
                display: inline-block;
            }
            @include respond(sm){
                margin-bottom: 0;
                justify-content: center;
                text-align: center;
            }
        }
    }

    &__content{
        &-box{
            position: relative;
            margin-bottom: 2.5rem;
            height: -webkit-fill-available;
            animation: moveInBottom .6s;
            animation-delay: .3s;
            animation-fill-mode: backwards;
        }
    }

    &__underlay{
        z-index: -1;
        height: inherit;
        width: 90%;
        position: absolute;
        top: -1.5rem;
        right: -1.5rem;
        &--primary{
            @include color-gradient-primary-1;
        }
        @include respond(xs){
            display: none;
        }
    }

    &__paragraph{
        &:not(:last-child){
            margin-bottom: 2rem;
        }
        &-box{
            padding: 3.6rem 4.2rem;
            margin-bottom: 2rem;
            
            &--design{
                box-shadow: $shadows-1;
                color: $color-grey-a-2;
                background-color: $color-theme;
            }
        }
        @include respond(xs){
            font-size: 2.6rem;
        }
    }
    &__btn {
        font-size: 2rem;
        padding: 1.6rem 4rem;
        animation: moveInBottom .6s;
        animation-delay: .7s;
        animation-fill-mode: backwards;
        @include default-btn;
        @include respond(md){
            &:not(:last-child){
                margin-right: 2rem;
            }
        }
        &-box{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(15rem, min-content));
            grid-gap: 2rem;
            @include respond(md){
                display: flex;
                &:not(:last-child){
                    margin-right: 2rem;
                }
            }
        }
    }



    &__image{
        padding: 0;

    &-box{
        position: relative;
        width: 30rem;
        height: 30rem;
        display: flex;
        animation: fadeIn .6s cubic-bezier(0.36, 0, 0.66, -0.56);
        animation-fill-mode: backwards;
        transition: hover rotate .8s;
        &:hover .main-title__image__background{
            transform:
                rotate(360deg)
                scale(1.1);
        }
        &:hover .main-title__image__format{
            transform: 
                scale(1.1);
        }

        @include respond(sm){
            justify-content: center;
            align-items: center;
        }
    }

    &__background{
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        z-index: -1;
        transform: rotate(-10deg);
        transition: all .6s cubic-bezier(.17,.67,.72,1.25);
        @include respond(xs){
            height: 23rem;
            width: 23rem;
        }
        &--primary{
            @include color-gradient-primary-1;
            clip-path: polygon(100% 0, 100% 59%, 50% 50%, 85% 100%, 0 100%, 0 0);
        }
    }

    &__format{
        border-radius: 50%;
        transition: all .6s cubic-bezier(.17,.67,.72,1.25);
        
        &--picture{
            width: 27rem;
            height: 27rem;
            @include respond(xs){
                height: 20rem;
                width: 20rem;
            }
        }
    }
    }
    &__responsive{
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 3rem;
        @include respond(sm){
            display: grid;
            grid-gap: 3rem;
            justify-content: center;
        }
        @include respond(xs){
            margin-bottom: 0;
            grid-gap: 0;
        }
        
    }
}