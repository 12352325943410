.footer{
    background-color: $color-primary-1;
    display: flex;
    justify-content: space-between;
    padding: 5rem;
    height: auto;
    @include respond(lg){
        flex-direction: column;
    }
    @include respond(xs){
        padding: 3rem;
    }
    
    &__copyright{
        margin-top: 3rem;
        text-align: center;
        color: $color-theme;
        font-size: 2rem;
        font-weight: 400;
    }
}