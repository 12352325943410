.display-image{
    &__layout{
        @include default-margin;
        animation: moveInBottom .9s;
        animation-delay: .6s;
        animation-fill-mode: backwards;
    }
    &__title{
        font-size: 3.6rem;
        margin-bottom: 2rem;
        &-box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 4rem;
            @include respond(md){
                padding: 0;
            }
        }
    }
    &__content{
        color: $color-grey-a-2;
        font-size: 2rem;
        font-family: lato;
    }

    &__image{
        object-fit: contain;
        width: 80%;
        @include respond(xs){
            width: 70%;
        }
        &-box{
            @include flex-center;
        }
    }
    &__background{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
        @include padding;
        box-shadow: $shadows-1;
        grid-gap: 5rem;
        @include borders;
        @include respond(xs){
            padding: 4rem 2rem;
            grid-gap: 5rem;
        }
    }
}