*,
 *::after,
 *::before { 
     margin: 0;
     padding: 0;
     box-sizing: inherit;
 }

html{
    font-size: 62.5%;  //1rem = 10px
    color: $color-grey-a-1;
    
    @include respond(lg){
        font-size: 56.25%;
    }

    @include respond(sm){
        font-size: 50%;
    }

    @include respond(xs){
        font-size: 37.5%;
    }
}
body {
   box-sizing: border-box;
   position: relative;
}    

