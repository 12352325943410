.react{
    &-tabs{
        &__tab{
            display: flex;
            justify-content: center;
            width: 100%;
            @include button-reset;
            @include default-btn;
            padding: 1.3rem 1.6rem;
            
            &-list{
                list-style-type: none;
            }
           
        }

        &__tab{
            &--selected{
                box-shadow: 0 2px 4px rgba($color-semantic-b-1, .4) !important;
                background-color: $color-semantic-b-1 !important;
                transform: translateY(1px) !important;
                position: relative !important;
                &:hover{
                    box-shadow: 0 2px 4px rgba($color-semantic-b-1, .2);
                    transform: translateY(1px);
                }
            }
        }
    }
}