.title-list{
    &__box{
        display: grid;
        grid-gap: 5rem;
        grid-column-gap: 7rem;
        grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
        padding: 5rem;
        position: relative;

        &--grid-3{
            grid-gap: 3rem;

            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        }
        @include respond(xs){
            padding: 3rem;
            grid-gap: 3rem;
        }
    }
    &__item{
        position: relative;
        padding-left: 10rem
    }
    &__title{
        font-size: 3.6rem;
        font-weight: bold;
        margin-bottom: .4rem;
        @include respond(xs){
            font-size: 2.6rem;
        }
    }
    &__subtitle{
        color: $color-grey-a-2;
        font-size: 2rem;
        margin-bottom: 1.4rem;
        @include respond(xs){
            font-size: 1.8rem;
        }

    }
    &__content{
        font-size: 1.8rem;
        @include respond(xs){
            font-size: 1.6rem;
        }
    }
    &__number{
        position: absolute;
        left: 0;
        font-size: 14.4rem;
        color: $color-grey-a-4;
        line-height: initial;
        font-weight: bold;
    }
}