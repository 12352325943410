.sources{
    background-color: $color-theme;
    box-shadow: $shadows-1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    grid-gap: 4rem;
    padding: 4rem;
    animation: moveInTop .6s;
    animation-fill-mode: backwards;
    @include respond(md){
        display: block;
    }

    &__sources{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-gap: 3rem;
 
    }

    &__text{
        color: $color-grey-a-1;
        font-weight: 400;
        font-size: 3.6rem;
        @include respond(md){
            display: none;
        }
    }

    &__img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        &-box{
            height: 10rem;
        }
    }
}

.references{
    margin: 4rem;
    @include respond(xs){
        margin: 0;
    }
    &-box{
        background-color: $color-theme;
        box-shadow: $shadows-1;
        animation: moveInBottom .6s;
        animation-delay: 1.7s;
        animation-fill-mode: backwards;
        margin-bottom: 5rem;
    }
}