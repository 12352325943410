.info-tab{
    &-box{
        display: flex;
        @include default-margin;
        
        @include respond(md){
            display: grid;
            grid-template-columns: 1fr;
            border-right: none;
        }
    }
    &__selector{
        color: $color-theme;
        text-align: left;
        font-family: inherit;
        font-weight: 700;
        font-size: 3.2rem;
        
        &-box{
            display: grid;
            grid-gap: 1rem;
            margin-right: 5rem;
            @include respond(md){
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }
        &-tab{
            display: flex;
            justify-content: flex-start;
            padding: 2rem 10rem 2rem 3.4rem;
            font-family: inherit;
            &:focus{
                outline: none;
            }
        }
    }
    &__content{
        font-size: 2rem;
        font-family: lato;
        text-align: left;
        &:not(:last-child){
            margin-bottom: 1rem;
        }
        &--title{
            font-size: 3.2rem;
            color: $color-grey-a-1;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }
        &-box{
            box-shadow: $shadows-1;
            width: 100%;
            height: inherit;
            @include borders;
            @include padding;
            color: $color-grey-a-2;
        }
    }
    &__tab{
        &-panel{
            justify-content: flex-start;
        }
    }
}