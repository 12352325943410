.section-title{
    animation: moveInBottom .6s;
    animation-fill-mode: backwards;
    animation-delay: .9s;
    &__title{
    color: $color-grey-a-1;
    font-size: 3.6rem;
    }
    
    &__divider{
        border: 1px solid $color-grey-a-1;
        margin: 1rem 0;
        margin-bottom: 3rem;
        @include respond(xs){
            margin-bottom: 2rem;
        }
    }
}