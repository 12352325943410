.projects{
    margin: 0 4rem;
    animation: moveInBottom .6s cubic-bezier(0.33, 1, 0.68, 1);
    animation-delay: 1.5s;
    animation-fill-mode: backwards;
    @include respond(xs){
        margin: 0;
    }
    &__links{
        @include button-reset;
        font-size: 3.6rem;
        text-align: center;
        color: $color-theme;
        &--content{
            font-weight: 500;
            &:hover{cursor: pointer;}
        }
        &--tab{
            display: flex;
            justify-content: center;
            width: 100%;
            @include button-reset;
            @include default-btn;
            padding: 1.3rem 1.6rem;
            animation: moveInBottom .6s cubic-bezier(0.33, 1, 0.68, 1);
            animation-fill-mode: backwards;
            cursor: pointer;
        }
        &--1{
            animation-delay: .5s;
        }
        &--2{
            animation-delay: .7s;
        }
        &--3{

            animation-delay: .9s;
        }
        &--4{

            animation-delay: 1.1s;
        }
        &--5{

            animation-delay: 1.3s;
        }

        &-box{
            margin: 4rem;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            grid-gap: 3rem;
            @include respond(xs){
                margin: 3rem 1rem;
            }
        }
    }
    &__section{
        @include default-margin;
    }
    
}

.project-card{
    display: flex;
    flex-direction: column;
    color: $color-grey-a-1;
    padding: 3.6rem 4.8rem;
    box-shadow: $shadows-4;
    position: relative;
    height: 100%;
    width: 100%;
    border-left: solid 1rem $color-primary-1;
    border-right: solid 29.7rem $color-primary-1;
    @include respond(md){
        border-right: solid 4rem $color-primary-1;
        border-left: solid 4rem $color-primary-1;
        padding: 3.6rem 2.8rem
    }
    @include respond(xs){
        border-left: solid 1rem $color-primary-1;
        border-right: solid 1rem $color-primary-1;
    }
    &__title{
        &-box{
            margin-bottom: 4rem;
        }
        &--sub{
            color: $color-grey-a-3;
            @include respond(md){
                font-size: 1.8rem;
            }
            &-box{
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(17rem, max-content));
                grid-gap: 2.5rem;
            }
        }
    }
    &--title{
        font-weight: bold;
        font-size: 4.6rem;
    }

    &__button{
        @include default-btn;
        font-size: 2.2rem;
        font-weight: 500;
        @include respond(md){
            font-size: 2.2rem;
        }
        &-box{
            display: flex;
        }
        
    }
    &__list{
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        grid-gap: 2rem;
        @include respond(md){
            grid-gap: 3rem;
        }
        @include respond(xs){
            grid-gap: 5rem;
        }
        &--title{
            width: 16.2rem;
            height: 15.9rem;
            & > * {
                line-height: 2;
            }
        }

        &--content{
            margin-top: 0;
            margin-bottom: 4rem;
        }

        &-item{
            & h3{
                margin-bottom: 1.8rem;
                color: $color-accent-1;
            }
            &--title{
                font-size: 2.4rem;
                @include respond(md){
                    font-size: 2.4rem;
                }
            }
            &--body{
                font-size: 1.5rem;
                font-family: lato;
                @include respond(md){
                    font-size: 1.8rem;
                }
            }
        }
    }   

    &__exit{
        @include button-reset;
        height: 4.5rem;
        width: 4.5rem;
        position: absolute;
        border: none;
        top: 1rem;
        right: -29rem;
        z-index: 2;
        background-image: url(../../../assets/design/Exit/Exit.svg);
        background-position: center;
        background-repeat: no-repeat;
        object-fit: contain;
        @include respond(md){
            right: -19rem;
        }
        @include respond(xs){
            height: 6.5rem;
            width: 6.5rem;
            right: -8.5rem;
        }
        &:hover{
            cursor: pointer;
        }
        
    }
}

.read-time{
    font-size: 1.2rem;
    margin-top: .6rem;
    @include respond(md){
        font-size: 1.6rem;
    }
}