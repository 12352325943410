@keyframes moveInLeft{
    0%{
        opacity: 0;
        transform: translateX(-10rem);
    }
    80%{
        transform: translateX(1rem);
    }

    100%{
        opacity: 1;
        transform: translate(0);
    }
}
@keyframes moveInRight{
    0%{
        opacity: 0;
        transform: translateX(10rem);
    }
    80%{
        transform: translateX(-1rem);
    }

    100%{
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom{
    0%{
        opacity: 0;
        transform: translateY(3rem);
    }

    100%{
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInTop{
  0%{
    opacity: 0;
    transform: translateY(-3rem);
  }

  80%{
    transform: translateY(.5rem);
  }

  100%{
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes grow{
  0%{
    transform: scale(.9);
  }
  50%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }

}

@keyframes pulse{
  0%{
    opacity: .9;
  }
  100%{
    opacity: 1;
  }
}

@keyframes spin{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}



// Fold Out
.awssld--foldOutAnimation {
    --fold-out-perspective: 2000px;
    --fold-out-angle: 90deg; }
    .awssld--foldOutAnimation .awssld__container {
      -webkit-perspective: var(--fold-out-perspective);
              perspective: var(--fold-out-perspective); }
    .awssld--foldOutAnimation .awssld--exit.awssld--moveLeft, .awssld--foldOutAnimation .awssld--exit.awssld--moveRight {
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d; }
    .awssld--foldOutAnimation .awssld--exit.awssld--moveLeft {
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-animation: foldLeft var(--slider-transition-duration) both var(--transition-bezier);
              animation: foldLeft var(--slider-transition-duration) both var(--transition-bezier); }
    .awssld--foldOutAnimation .awssld--exit.awssld--moveRight {
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      -webkit-animation: foldRight var(--slider-transition-duration) both var(--transition-bezier);
              animation: foldRight var(--slider-transition-duration) both var(--transition-bezier); }
  
  @-webkit-keyframes foldLeft {
    from {
      -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
              transform: translate3d(0, 0, 0) rotateY(0deg); }
    to {
      -webkit-transform: translate3d(-100%, 0, 0) rotateY(calc(var(--fold-out-angle) * -1));
              transform: translate3d(-100%, 0, 0) rotateY(calc(var(--fold-out-angle) * -1)); } }
  
  @keyframes foldLeft {
    from {
      -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
              transform: translate3d(0, 0, 0) rotateY(0deg); }
    to {
      -webkit-transform: translate3d(-100%, 0, 0) rotateY(calc(var(--fold-out-angle) * -1));
              transform: translate3d(-100%, 0, 0) rotateY(calc(var(--fold-out-angle) * -1)); } }
  
  @-webkit-keyframes foldRight {
    from {
      -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
              transform: translate3d(0, 0, 0) rotateY(0deg); }
    to {
      -webkit-transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle));
              transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle)); } }
  
  @keyframes foldRight {
    from {
      -webkit-transform: translate3d(0, 0, 0) rotateY(0deg);
              transform: translate3d(0, 0, 0) rotateY(0deg); }
    to {
      -webkit-transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle));
              transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle)); } }
  


//Open
.awssld--openAnimation {
  --open-animation-perspective: 600px;
  --open-animation-angle: 8deg;
  --open-animation-enter-delay: 85ms; }
  .awssld--openAnimation .awssld__container {
    -webkit-perspective: var(--open-animation-perspective);
            perspective: var(--open-animation-perspective); }
  .awssld--openAnimation .awssld--moveLeft, .awssld--openAnimation .awssld--moveRight {
    -webkit-animation-delay: var(--open-animation-enter-delay);
            animation-delay: var(--open-animation-enter-delay); }
  .awssld--openAnimation .awssld--exit.awssld--moveLeft {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-animation: openRight var(--slider-transition-duration) both var(--transition-bezier);
            animation: openRight var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--openAnimation .awssld--exit.awssld--moveRight {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-animation: openLeft var(--slider-transition-duration) both var(--transition-bezier);
            animation: openLeft var(--slider-transition-duration) both var(--transition-bezier); }

@-webkit-keyframes openLeft {
  45% {
    -webkit-transform: rotateY(calc(var(--open-animation-angle) * -1));
            transform: rotateY(calc(var(--open-animation-angle) * -1)); } }

@keyframes openLeft {
  45% {
    -webkit-transform: rotateY(calc(var(--open-animation-angle) * -1));
            transform: rotateY(calc(var(--open-animation-angle) * -1)); } }

@-webkit-keyframes openRight {
  45% {
    -webkit-transform: rotateY(var(--open-animation-angle));
            transform: rotateY(var(--open-animation-angle)); } }

@keyframes openRight {
  45% {
    -webkit-transform: rotateY(var(--open-animation-angle));
            transform: rotateY(var(--open-animation-angle)); } }

