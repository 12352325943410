.content-gallery{
    &__box{
        @include default-margin;
        @include padding;
        box-shadow: $shadows-1;
        position: relative;
    }
    &__header{
        &--box{
            box-shadow: $shadows-1;
            width: 50%;
            position: absolute;
            top: -9%;
            left: 0;
            padding: 1.5rem 5rem;
            display: flex;
            align-content: center;
            background-color: $color-theme;
        }
        &--title{
            z-index: 999;
        }
    }
    &__pointer{

    }
    &__items{

    }
    &__item{
        padding: 5.2rem;
        &:nth-child(4n-3){
            border-right: .6rem #f9f9f9 solid;
            border-bottom: .6rem #f9f9f9 solid;
        }
        &:nth-child(4n-2){
            border-left: .6rem #f9f9f9 solid;
            border-bottom: .6rem #f9f9f9 solid;
        }
        &:nth-child(4n-1){
            border-top: .6rem #f9f9f9 solid;
            border-right: .6rem #f9f9f9 solid;
        }
        &:nth-child(4n){
            border-top: .6rem #f9f9f9 solid;
            border-left: .6rem #f9f9f9 solid;
        }
        &--title{
            font-size: 3.2rem;
            color: $color-grey-a-2;
            font-weight: medium;
            margin-bottom: 1.4rem;
        }
        &--content{
            color: #555a5f;
            font-size: 2rem;
            font-family: lato;
            font-weight: light;
        }
    }
}