.section-card{
    box-shadow: $shadows-1;
    position: relative;
    @include default-margin;
    @include borders;
    border-right: none;
    animation: moveInBottom .6s .6s;
    animation-fill-mode: backwards;
    &::after{
        content: "";
        background-color: $color-primary-1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        clip-path: polygon(67% 0, 100% 0, 100% 100%);
        @include respond(sm){
            background-color: $color-theme;
            clip-path: polygon(72% 0, 100% 0, 100% 60%);
        }
    }
    &__title{
        color: $color-primary-1;
        background-color: $color-primary-5;
        &__box{
            display: flex;
            flex-direction: column;
            padding: 5rem 0 0 5rem;
            @include respond(xs){
                padding: 3.5rem 1.5rem;
                padding-bottom: 0;
            }
        }
    }
    &__subtitle{
        color: $color-theme;
        background-color: $color-primary-1;
    }
    &__content{
        width: 55%;
        padding: 5rem 0 8rem 5rem;
        @include respond(md){
            width: 70%;
        }
        @include respond(sm){
            width: 100%;
            padding: 5rem;
        }
        @include respond(xs){
            padding: 3.5rem 1.5rem;
        }
        &-title{
            font-size: 3.6rem;
            margin-bottom: 2rem;
            
        }
        &-paragraph{
            font-size: 2rem;
            color: $color-grey-a-2;
            font-family: lato;
            &:not(:last-child){
                margin-bottom: 2rem;
            }
        }
    }
}

.section-card__title, .section-card__subtitle{
    font-size: 3.6rem;
    padding: 1.4rem;
    width: 30%;
    text-transform: uppercase;
    @include respond(md){
        width: 55%;
    }
    @include respond(sm){
        width: 100%;
    }
}