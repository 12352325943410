.contact{
    &__box{
        background-color: $color-theme;
        padding: 5rem;
        padding-top: 3rem;
        box-shadow: $shadows-1;
        border: none;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include respond(lg){
            width: 100%;
            margin-bottom: 3rem;
        }
    }
    &--title{
        text-transform: uppercase;
        color: $color-accent-1;
        font-size: 2.6rem;
        font-weight: bold;
        height: min-content;

        &-box{
            grid-area: header;
            margin-bottom: 2rem;
        }
    }

    &__form{
        &-box{
            display: grid;
            grid-auto-rows: 1fr;
            grid-auto-columns: 1fr;
            grid-template-areas:
            'input1 message message'
            'input2 message message'
            'input3 button  null';
            grid-gap: 3rem;
            width: 100%;
            @include respond(md){
                grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
                grid-template-rows: 1fr 1fr 1fr 20rem 1fr;
                grid-gap: 2rem;
                grid-template-areas:
                'input1 input1'
                'input2 input2'
                'input3 input3'
                'message message'
                'button button'
            }
        }
        &--input:placeholder-shown + &--label{
            opacity: 0;
            visibility: hidden;
            transform: translateY(-4rem);
        }
        &--input{
            font-family: inherit;
            font-size: 1.8rem;
            color: $color-grey-a-1;
            padding: 1.5rem;
            border: none;
            background-color: #E8E9E9;
            border-bottom: 3px solid #c4c4c4;
            display: block;
            transition: all .3s;
            @include respond(xs){
                font-size: 3.2rem;
            }
            &-box{
                position: relative;
                &--1{
                    grid-area: input1;
                }
                &--2{
                    grid-area: input2;
                }
                &--3{
                    grid-area: input3;
                }
                &--4{
                    grid-area: message;
                }
            }
            &::placeholder{
                color: #A3A3A4;
            }
            &:focus{
                outline: none;
                border-bottom: 3px solid $color-primary-1;
                box-shadow: $shadows-1;
                color: $color-grey-a-1;
                transform: translateY(-3px);
                &:invalid{
                    border-bottom: 3px solid $color-semantic-a-1;
                }
            }
            
            
        }
        &--label{
            transition: all .2s;
            margin-top: .7rem;
            display: block;
            font-size: 1rem;
            color: #A3A3A4;
            position: absolute;
        }
        
        &__details{
            &-box{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
               
            }
            &--input{
                width: 100%;
                height: 100%;
            }
            &--label{

            }
        }
        &__message{
            &-box{
               
            }
            &--input{
                resize: none;
                width: 100%;
                height: 100%;
            }
            &--label{

            }
        }
    }
    &__btn{
        @include button-reset;
        cursor: pointer;
        background-color: $color-semantic-b-3;
        color: $color-theme;
        font-size: 1.8rem;
        width: 100%;
        padding: 1.5rem;
        height: 100%;
        transition: all .2s;
        text-align: left;
        &:hover{
            background-color: $color-semantic-b-1;
            box-shadow: $shadows-2;
            transform: translateY(-2px);
        }
        &:focus{
            transform: translateY(0);
            box-shadow: none;
        }
        &--disabled{
            background-color: $color-grey-a-3;
            &:hover{
                transform: none;
                box-shadow: none;
                background-color: $color-grey-a-3;
                cursor: default;
            }
        }
        &--updater{
            position: absolute;
            bottom: -40%;
            color: $color-grey-a-1
        }
        &-box{
            position: relative;
            grid-area: button;
        }
    }
}