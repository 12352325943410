.about{
    &-container{
        margin: 7rem 5rem 5rem;
        @include respond(xs){
            margin: 4rem 2rem 2rem;
        }
    }
}

.skills{
    padding-top: 0;
    padding-bottom: 0;
    @include respond(md){
        padding: 0 4rem 4rem;
    }
    @include respond(xs){
        padding: 0 1rem 4rem;
    }

    &__divider{
        margin-bottom: 5rem;
        @include respond(md){
            margin-bottom: 0;
        }
    }
    &__layout{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
        grid-gap: 5rem;
        margin-bottom: 5rem;
        @include respond(md){
            margin-bottom: 3rem;
        }
        @include respond(xs){
            grid-gap: 2rem;
        }
    }
}

.education{
    padding: 0 9.5rem 4rem;
    
    @include respond(lg){
        padding: 0 6.5rem 4rem;
    }
    @include respond(md){
        padding: 0 4rem 4rem;
    }
    @include respond(xs){
        padding: 0 1rem 4rem;
    }
    
    &__title{
        font-size: 3.6rem;
        &-box{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &__section--title-box{
            margin-top: 0;
        }
    }
    &__subtitle{
        font-weight: 400;
        font-size: 2.2rem;
    }
    &-box{
        padding: 6rem;
        display: grid;
        grid-gap: 4rem;
        grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
    }
    &__content{
        font-size: 2.2rem;
        &-box{
            display: flex;
            align-items: center;
        }
    }
}