.default-layout{
    padding: 13rem 9.5rem 5rem;
    color: $color-grey-a-1;
    @include respond(lg){
        padding: 13rem 6.5rem 5rem;
    }
    @include respond(md){
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 4rem;
    }
}

.default-card{
    background-color: $color-theme;
    box-shadow: $shadows-1;
    @include borders;
    padding: 5rem;
    margin-bottom: 5rem;
    width: 100%;
    animation: moveInBottom .6s .6s;
    animation-fill-mode: backwards;
}